@font-face {
  font-family:Circular;
  font-style:normal;
  font-weight:500;
  src:url(fonts/circularstd-medium-webfont.woff2) format("woff2");
  font-display:swap
}
@font-face {
  font-family:Basis Grotesque;
  font-style:normal;
  font-weight:500;
  src:url(fonts/basis_grotesque_mono_pro_regular-webfont.woff2) format("woff2");
  font-display:swap
}

:root {
  --color:#2C7BE5
}

body,
html {
  width:100%;
  height:100%
}
body {
  font-size:14px;
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  background-color:#181818;
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  font-family:Circular,"monospace",sans-serif;
  cursor:default
}
::-moz-selection {
  background:transparent;
  text-shadow:none
}
::selection {
  background:transparent;
  text-shadow:none
}
ul {
  list-style:none;
  margin:0;
  padding:0
}
ul.inline li {
  display:inline-block;
  margin-right:1em
}
ul.inline li:last-child {
  margin-right:0
}
a {
  text-decoration:none;
  cursor:pointer
}
a:focus {
  outline:0
}
h1,
h2,
h3 {
  font-weight:400;
  font-size:1em;
  line-height:1.2;
  margin:0
}
.logo,
.social,
.worked-with,
.impressum {
  font-size:10px
}

.impressum {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #353d3f;
}

.impressum p {
  margin: 3px 0;
}

@media (min-width:750px) {
  .logo,
  .social,
  .worked-with {
    font-size:14px
  }
}
.inner {
  position:relative;
  margin:0 auto;
  width:80%
}

@media (min-width:750px) {
  .inner {
    width:75%
  }
}

.logo img {
  border-radius: 50%;
   filter: grayscale(100%);
}

.inner--link {
  display:flex;
  align-items:baseline
}

.table {
  display:table
}

.table,
.table-cell {
  width:100%;
  height:100%
}

.table-cell {
  display:table-cell;
  vertical-align:middle
}

.js-colors {
  height:50vh;
  position:fixed;
  top:50vh;
  left:0;
  right:0;
  display:flex;
  z-index:400
}

.color {
  flex:1
}

.hero-text {
  display:inline-block;
  margin:1em auto;
  position:relative;
  min-height:20vw
}

.hero-text:after {
  content:"";
  display:block;
  opacity:0;
  position:absolute;
  top:0;
  left:-1.3em;
  transform:translateY(5px) scale(.9);
  transition:all .3s cubic-bezier(.19,1,.22,1)
}
.hero-text:hover:after {
  opacity:1;
  transform:translateX(0) scale(1)
}
.hero-text .js-typer {
  display:none
}
.hero-text+.hero-text {
  display:inline;
  margin:0
}
.highlight {
  color:transparent;
  border-radius:1px
}
.hidden {
  display:none
}
.social-link,
.social-link:active,
.social-link:link,
.social-link:visited {
  color:#fff
}
.logo,
.social {
  animation-duration:.3s;
  animation-delay:3s;
  animation-fill-mode:both
}
.logo {
  display:inline-block;
  animation-name:fadeBounceDown;
  color:#f1f1f1
}
.social {
  animation-name:fadeBounceUp
}
.about {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  z-index:0;
  transition:background-color 30s linear
}
.browser-height {
  height:100%;
  pointer-events:none
}
.hero-text,
.project-highlight {
  font-family:Circular,Helvetica Neue,sans-serif;
  font-size:7vw;
  font-weight:500;
  line-height:1;
  letter-spacing:-.045em
}
.footer {
  position:relative;
  z-index:1;
  animation-name:navPeak;
  animation-duration:.3s;
  animation-delay:3s;
  animation-fill-mode:both
}
.projects {
  padding:5vw 0
}
.project-link {
  display:block;
  padding:.75vw 0;
  margin-bottom:1vw;
  color:rgba(24,24,24,.6);
  position:relative
}
.project-link:after {
  content:"";
  display:block;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(0,0,0,.3);
  opacity:0;
  transform:scaleY(.3);
  transition:transform .3s cubic-bezier(.19,1,.22,1)
}
.project-link:focus:after,
.project-link:hover:after {
  opacity:1;
  transform:scaleY(1)
}
.project-highlight {
  pointer-events:none;
  border-radius:1px
}
.worked-with {
  color:rgba(24,24,24,.6);
  padding-bottom:10vw
}
.worked-with a {
  color:rgba(24,24,24,.6);
  border-bottom:1px solid transparent
}
.worked-with a:focus,
.worked-with a:hover {
  border-bottom:1px solid
}
.worked-with--label {
  font-weight:500;
  padding-bottom:.5em
}
.available,
.worked-with--label {
  font-family:Circular,Helvetica Neue,sans-serif
}
.available {
  position:relative;
  font-size:1.25em;
  line-height:1.2;
  color:#f1f1f1;
  letter-spacing:-.015em;
  margin-bottom:3em
}
.available a {
  color:#6d6d6d;
  position:relative;
  transition:color .3s linear
}
.available a:before {
  position:absolute;
  content:"👋";
  display:inline-block;
  top:.1em;
  left:-1.5em;
  opacity:0;
  transform-origin:50% 90%;
  animation-name:wave;
  animation-duration:.35s;
  animation-timing-function:linear;
  animation-iteration-count:infinite
}
.available a:hover:before {
  opacity:1
}
.available .emoji {
  position:absolute;
  left:-1.5em
}
.available a:focus,
.available a:hover,
.logo:focus,
.logo:hover,
.social-link:focus,
.social-link:hover {
  color:var(--color);
  border-bottom:1px solid
}
.hero-text {
  color:var(--color)
}
.highlight,
.projects,
.worked-with {
  background-color:var(--color)
}

.cursor {
  opacity: 1;
}
.cursor.blinking {
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink {
  50% { opacity: 0.0; }
}
@-webkit-keyframes typedjsBlink {
  0% { opacity: 1; }
  50% { opacity: 0.0; }
  100% { opacity: 1; }
}